import * as React from "react";
import toast from "react-hot-toast";

import { Generic } from "src/@types/Category";
import { ROLES } from "src/constants/common";

import agent from "src/helpers/apiAgent";

import useAuth from "src/hooks/useAuth";
import useSchool from "src/hooks/useSchool";

const TEACHER = {
  email: "",
  phone: "",
  fullName: "",
  schoolId: "",
  picturePath: "",
  RoleId: ROLES?.TEACHER,
};

// Set defaults for reuse
const DEFAULTS = {
  loaders: {
    teachers: false,
    assignCourse: false,
    createTeacher: false,
    createBulkTeacher: false,
    assignedCoursesList: false,
  },
  teachers: [],
  refetch: false,
  teacherData: { ...TEACHER },
  resetTeacherData: () => {},
  addTeacher: (callback) => {},
  setRefetch: (val: Generic) => {},
  setTeacherData: (data: Generic) => {},
  addBulkTeachers: (data: Generic, callback) => {},
  getTeacherCourses: async (teacherId: string) => [],
  assignCourseToTeacher: async (teacher, selectedCourseId, callback) => {},
};
const TeacherContext = React.createContext(DEFAULTS);

const TeacherProvider: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
}) => {
  const { isLoggedIn } = useAuth();
  const { activeSchool } = useSchool();

  const [refetch, setRefetch] = React.useState(false);
  const [loaders, setLoaders] = React.useState(DEFAULTS.loaders);
  const [teachers, setTeachers] = React.useState(DEFAULTS.teachers);
  const [teacherData, setTeacherData] = React.useState(DEFAULTS.teacherData);

  React.useEffect(() => {
    if (activeSchool?.id) {
      setTeacherData((prev) => ({ ...prev, schoolId: activeSchool?.id }));
    }
  }, [activeSchool]);

  React.useEffect(() => {
    const getTeachersBySchoolId = async () => {
      try {
        setLoaders((prev) => ({ ...prev, teachers: true }));
        const response = await agent.Teacher.getTeachersBySchoolId(
          activeSchool?.id
        );

        if (response.code === 200) {
          setTeachers(response?.data);
        }
      } catch (error) {
      } finally {
        setLoaders((prev) => ({ ...prev, teachers: false }));
      }
    };
    if (isLoggedIn && activeSchool?.id) {
      getTeachersBySchoolId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, activeSchool, refetch]);

  const validateCreation = (data) => {
    if (!data?.schoolId) {
      toast.error("Teacher's school is not associated!");
      return false;
    }
    if (
      !data?.fullName ||
      data?.fullName < 3 ||
      data?.fullName?.trim()?.length === 0
    ) {
      toast.error("Teacher's name length should be greater than 3 characters!");
      return false;
    }
    if (!data?.email || data?.email?.trim()?.length === 0) {
      toast.error("Teacher's email not provided!");
      return false;
    }
    if (
      !data?.phone ||
      !(data?.phone?.length >= 10 && data?.phone?.length < 15) ||
      data?.phone?.trim()?.length === 0
    ) {
      toast.error(
        "Teacher's phone number length should between 10 to 15 digits!"
      );
      return false;
    }

    return true;
  };

  const addTeacher = async (callback) => {
    try {
      if (validateCreation(teacherData)) {
        setLoaders((prev) => ({ ...prev, createTeacher: true }));
        const response = await agent.Teacher.createTeacher(teacherData);

        if (response.code === 201) {
          toast.success("Teacher created successfully!");
          setRefetch(!refetch);

          if (callback) {
            resetTeacherData();
            callback();
          }
        }
      }
    } catch (error) {
    } finally {
      setLoaders((prev) => ({ ...prev, createTeacher: false }));
    }
  };

  const resetTeacherData = () =>
    // Preserving schoolId
    setTeacherData({ ...TEACHER, schoolId: activeSchool?.id });

  const getTeacherCourses = async (teacherId) => {
    try {
      setLoaders((prev) => ({ ...prev, assignedCoursesList: false }));
      const response = await agent.Teacher.getTeacherCourses(teacherId);
      if (response.code === 200) {
        return response?.data?.teacherCourses;
      }
      return [];
    } catch (error) {
      return [];
    } finally {
      setLoaders((prev) => ({ ...prev, assignedCoursesList: false }));
    }
  };

  const validateCourseAssignment = (selectedCourseId, teacherId) => {
    if (!teacherId) {
      toast.error("Please select a teacher to assign course!");
      return false;
    }
    if (!selectedCourseId) {
      toast.error("Please select a course to assign!");
      return false;
    }

    return true;
  };

  const assignCourseToTeacher = async (teacher, selectedCourseId, callback) => {
    try {
      if (validateCourseAssignment(selectedCourseId, teacher?.teacherId)) {
        setLoaders((prev) => ({ ...prev, assignCourse: false }));
        const response = await agent.Teacher.addTeacherInCourse({
          TeacherId: teacher?.teacherId,
          CourseId: selectedCourseId,
        });

        if (response.code === 201) {
          toast.success("Course assigned successfully!");
          setRefetch(!refetch);

          if (callback) {
            callback();
          }
        }
      }
    } catch (error) {
    } finally {
      setLoaders((prev) => ({ ...prev, assignCourse: false }));
    }
  };

  const validateBulkCreation = (data) => {
    if (!Array.isArray(data)) {
      toast.error("Failed to obtain teachers data!");
      return false;
    }
    if (data?.length === 0) {
      toast.error("Failed to obtain teachers data!");
      return false;
    }
    if (data?.some((t) => !(t?.name || t?.email))) {
      toast.error("Some teacher's name or email is missing!");
      return false;
    }

    return true;
  };

  const addBulkTeachers = async (bulkTeacherData, callback) => {
    try {
      if (validateBulkCreation(bulkTeacherData)) {
        bulkTeacherData = bulkTeacherData?.map((t) => ({
          ...t,
          RoleId: ROLES?.TEACHER,
          schoolId: activeSchool?.id,
        }));
        setLoaders((prev) => ({ ...prev, createBulkTeacher: true }));
        const response = await agent.Teacher.bulkCreateTeacher(bulkTeacherData);

        if (response.code === 201) {
          toast.success("Teachers created successfully!");
          setRefetch(!refetch);

          if (callback) {
            callback();
          }
        }
      }
    } catch (error) {
    } finally {
      setLoaders((prev) => ({ ...prev, createBulkTeacher: false }));
    }
  };

  const contextValues = {
    teachers,
    loaders,
    refetch,
    addTeacher,
    setRefetch,
    teacherData,
    setTeacherData,
    addBulkTeachers,
    resetTeacherData,
    getTeacherCourses,
    assignCourseToTeacher,
  };

  return (
    <TeacherContext.Provider value={contextValues}>
      {children}
    </TeacherContext.Provider>
  );
};

export { TeacherContext };
export default TeacherProvider;
